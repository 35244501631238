import './index.css';
import { SearchOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Select, message, Modal, Input, Space, Radio } from 'antd';
import React, { useState, useEffect } from 'react';
import { api, useAPI } from '../../api';
import DrugClassResultsTableMultiDrug from '../../components/DrugClassResultsTableMultiDrug';
// import { debounce } from 'lodash';
// import ContactModal from '../../components/ContactModal';
import FormSuggestInput from '../../components/FormSuggestInput';
import StatusSelect from '../DrugClassSearchNew/StatusInput';

const SEARCH_FORM_FIELDS = {
    PartDCode: 'partDCode',
    SelectedPlan: 'selectedPlan',
    CountyCode: 'countyCode',
    Plan: 'plan',
};

const SEARCH_METHOD = {
  BY_PARTD: 'byPartD',
  BY_COUNTY_INSURANCE: 'byCountyInsurance'
};

export default function MultiDrugSearch() {
    const [form] = Form.useForm();
    const multiSearchDrugInfoByPartDCode = useAPI(api.multiSearchDrugInfoByPartDCode);
    const searchInsurancePlansByPartDCode = useAPI(api.searchInsurancePlansByPartDCode);
    const [results, setResults] = useState([]);
    const [planOptions, setPlanOptions] = useState([]);
    const [, setSelectedPlan] = useState('');
    const [selectedPlanDetails, setSelectedPlanDetails] = useState({});
    const [expandedRow, setExpandedRow] = useState(null);
    const [deductible, setDeductible] = useState('');
    // const [isModalVisible, setIsModalVisible] = useState(false);
    const [isInstructionsModalVisible, setIsInstructionsModalVisible] = useState(false);
    const [isContactModalVisible, setIsContactModalVisible] = useState(false);

    const [searchMethod, setSearchMethod] = useState(SEARCH_METHOD.BY_PARTD);
    const getInsurancePlans = useAPI(api.getInsurancePlans);


    async function planOptionsDepsChangeCallback() {
      const countyCode = form.getFieldValue(SEARCH_FORM_FIELDS.CountyCode);
      console.log('County Code from form:', countyCode);
  
      if (!countyCode) {
        setPlanOptions([]);
        message.error('Please enter a county name.');
        return;
      }
      const includeMA = true;
      const includePDP = true;
      
      if (!(includeMA || includePDP)) {
        setPlanOptions([]); // Reset plan options if no plan types are selected
        return;
      }
    
      try {
        const response = await getInsurancePlans(countyCode, includeMA, includePDP);
        if (response && Array.isArray(response.plans)) {
          const uniquePlans = new Map();
          response.plans.forEach(plan => {
            console.log(plan);
            const compositeKey = `${plan.formulary_id}-${plan.plan_id}`;
            uniquePlans.set(compositeKey, plan);
          });
    
          const sortedPlanOptions = Array.from(uniquePlans.values())
            .sort((a, b) => a.plan_name.localeCompare(b.plan_name))
            .map(plan => ({
              label: `${plan.plan_name} (Formulary: ${plan.formulary_id}) - ${plan.contract_name}`,
              value: `${plan.formulary_id}-${plan.plan_id}`, // Ensure this composite key is unique
              planDetails: {
                formulary_id: plan.formulary_id,
                contract_id: plan.contract_id,
                plan_id: plan.plan_id,
                segment_id: plan.segment_id,
                deductible: plan.deductible
              }
            }));
          setPlanOptions(sortedPlanOptions);
        }
      } catch (ex) {
        console.error('Error fetching insurance plans:', ex);
        message.error('Error fetching insurance plans: ' + (ex.message || ex));
      }
    }
    
    useEffect(() => {
      const plan = form.getFieldValue(SEARCH_FORM_FIELDS.Plan);
      if (plan && planOptions.find(option => option.value === plan) === undefined) {
        form.setFieldValue(SEARCH_FORM_FIELDS.Plan, '');
      }
    }, [planOptions, form]);

    const handleFormSubmit = async (values) => {
      const drugNames = form.getFieldValue('drugs');
      const {
        formulary_id = '',
        contract_id = '',
        plan_id = '',
        segment_id = ''
      } = selectedPlanDetails;
    
      if (!formulary_id) {
        console.error('Formulary ID is missing.');
        message.error('Please select a plan before searching.');
        return;
      }
    
      const requestBody = {
        drugNames,
        formulary_id,
        contract_id,
        plan_id,
        segment_id
      };
    
      console.log('Requesting drug info with:', requestBody);
    
      try {
        const countyCode = form.getFieldValue(SEARCH_FORM_FIELDS.CountyCode);  // Moved up here for accessibility
        if (searchMethod === SEARCH_METHOD.BY_PARTD) {
          const data = await multiSearchDrugInfoByPartDCode(drugNames, formulary_id, contract_id, plan_id, segment_id);
          const updatedResults = data.drug_results.map(drug => ({ ...drug }));
          setResults(updatedResults);
          setSelectedPlan(formulary_id);
        } else if (searchMethod === SEARCH_METHOD.BY_COUNTY_INSURANCE && countyCode) {
          
          if (!countyCode) {
            console.error('County Code is missing.');
            message.error('Please enter a county name.');
            return;
          }
    
          const data = await multiSearchDrugInfoByPartDCode(drugNames, formulary_id, contract_id, plan_id, segment_id);
          const updatedResults = data.drug_results.map(drug => ({ ...drug }));
          setResults(updatedResults);
          setSelectedPlan(formulary_id);
        }
      } catch (error) {
        console.error('Error fetching drug class info:', error);
        message.error('An error occurred while fetching the drug class info.');
      }
    };
    
      
    const fetchPlansBasedOnPartDCode = async (partDCode) => {
      if (!partDCode || (partDCode.length !== 8 && partDCode.length !== 11)) {
          setPlanOptions([]);
          const error = new Error('PartD/Plan Benefits Code must be 8 or 11 characters long.');
          error.help = 'PartD/Plan Benefits Code must be 8 or 11 characters long.';
          throw error;
      }

      try {
        const response = await searchInsurancePlansByPartDCode(partDCode);
        if (response && response.formulary_id) { // Check for the existence of formulary_id or another unique field
          // Directly use the response object since it's not an array of plans
          setPlanOptions([{
            label: `${response.plan_name} - ${response.contract_name}`,
            value: response.formulary_id,
            planDetails: response // Storing the entire plan object for later use
          }]);
        } else {
          setPlanOptions([]);
          const error = new Error('No plan details found.');
          error.help = 'No plan details found.';
          throw error;
        }
      } catch (error) {
        console.error('Error:', error);
        const throwErr = new Error('No plan details found, please re-check your input.');
        throwErr.help = 'No plan details found, please re-check your input.';
        throw throwErr;
      }
    };
  const debouncedSearchPlans = fetchPlansBasedOnPartDCode;//debounce(fetchPlansBasedOnPartDCode, 800);


  useEffect(() => {
    const partDCode = form.getFieldValue(SEARCH_FORM_FIELDS.PartDCode);
    // Only call debouncedSearchPlans if partDCode has a valid length
    if (partDCode && (partDCode.length === 8 || partDCode.length === 11)) {
      debouncedSearchPlans(partDCode);
    }
  }, [form, debouncedSearchPlans]);

  const handlePlanChange = (value) => {
    const selectedOption = planOptions.find(option => option.value === value);
    if (selectedOption && selectedOption.planDetails) {
        setSelectedPlanDetails(selectedOption.planDetails);
        setDeductible(selectedOption.planDetails.deductible);
        form.setFieldsValue({ [SEARCH_FORM_FIELDS.PartDCode]: selectedOption.planDetails.formulary_id });
    } else {
        console.error('Selected plan details not found.');
        setSelectedPlanDetails({});
        setDeductible(undefined);
        // Reset the PartDCode in the form state
        form.setFieldsValue({ [SEARCH_FORM_FIELDS.PartDCode]: '' });
        alert('Plan details are incomplete or missing.');
    }
  };
  

  const handleInstructionsModalCancel = () => {
    setIsInstructionsModalVisible(false);
  };

  const handleContactModalSubmitPartD = async (values) => {
    try {
      const response = await fetch('/send-contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        message.success('Your request has been submitted. We will contact you shortly.');
        // Handle successful submission (e.g., close the modal)
        setIsContactModalVisible(false);
      } else {
        // Handle server errors
        message.error(data.message || 'An error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle network errors
      console.error('There was an error submitting the form:', error);
      message.error('There was a network error. Please try again later.');
    }
  };
  const handleContactModalCancel = () => {
    setIsContactModalVisible(false);
  };

  const showContactModal = () => {
    setIsInstructionsModalVisible(false);
    setIsContactModalVisible(true);
  };

  const handleSearchMethodChange = (e) => {
    setSearchMethod(e.target.value);
    form.resetFields();
  };
    
      return (
        <>
          <Button
            onClick={showContactModal}
            className="floating-help-button"
            style={{
              marginBottom: 16,
              backgroundColor: '#f5f5f5', // This is a light gray color similar to the table header
              color: 'black', // The text color is black
              borderColor: '#f5f5f5' // The border color is the same as the background
            }}
          >
            <strong>Ask for Help</strong>
          </Button>

          <Radio.Group onChange={handleSearchMethodChange} value={searchMethod} style={{ marginBottom: 16 }}>
            <Radio.Button value={SEARCH_METHOD.BY_PARTD}>Search by PartD Code</Radio.Button>
            <Radio.Button value={SEARCH_METHOD.BY_COUNTY_INSURANCE}>Search by Insurance Name</Radio.Button>
          </Radio.Group>

          <Form 
            form={form} 
            layout="vertical" 
            onFinish={handleFormSubmit} 
            style={{ maxWidth: 600 }}
            className="custom-form" 
            initialValues={{
                drugs: [''], 
              }}
           >
            {searchMethod === SEARCH_METHOD.BY_PARTD && (
            <>
              <Form.Item
              name={SEARCH_FORM_FIELDS.PartDCode}
              label={
                <span>
                  PartD/Plan Benefits Code <button type="button" onClick={() => setIsInstructionsModalVisible(true)} className="link-button" style={{ padding: 0, backgroundColor: 'transparent', border: 'none', color: '#007bff', textDecoration: 'underline' }}>(how to find?)</button>
                </span>
              }
              rules={[{ required: true, message: 'Please enter the PartD/Plan Benefits Code.' }]}
            >
            <StatusSelect
            options = {planOptions}
            onSearch={debouncedSearchPlans}
            onChange={handlePlanChange}
            />
          </Form.Item>
            <Modal
              title="How to Find PartD/Plan Benefits Code"
              visible={isInstructionsModalVisible}
              onCancel={handleInstructionsModalCancel}
              footer={[
                <Button key="back" onClick={handleInstructionsModalCancel}>
                  Close
                </Button>,
              ]}
            >
              <ul>
                <li>Check insurance card for a code starts with a letter (H, R, or S) followed by a series of 7 or 10 digits.</li>
                <li>Example codes might look like <strong>H6158-001</strong> or <strong>H6158-001-002</strong>.</li>
                <li>If you cannot find the code, <button type="button" onClick={showContactModal} className="link-button" style={{ padding: 0, backgroundColor: 'transparent', border: 'none', color: '#007bff', textDecoration: 'underline' }}>contact us</button> and we will help you find it</li>
                <img src="https://i.imgur.com/ovIpEVp.jpeg" alt="Example PartD Code" style={{ maxWidth: '100%', marginTop: '20px' }} />
              </ul>
            </Modal>
            <Modal
              title="Contact Us for Help"
              visible={isContactModalVisible}
              onCancel={handleContactModalCancel}
              footer={null}
            >
              <Form onFinish={handleContactModalSubmitPartD}>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: 'Please input your name!' }]}
                >
                  <Input placeholder="Your Name" />
                </Form.Item>
                <Form.Item
                  name="email"
                  rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
                >
                  <Input placeholder="Your Email" />
                </Form.Item>
                <Form.Item
                  name="message"
                  rules={[{ required: true, message: 'Please provide insurance details or patient info.' }]}
                >
                  <Input.TextArea 
                    placeholder="Provide any information you have about the patient's insurance, or provide the patient's name and date of birth and we will assist you in finding it."
                    rows={4}
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Modal>
            </>
          )}
          {searchMethod === SEARCH_METHOD.BY_COUNTY_INSURANCE && (
            <>
              <FormSuggestInput
              name={SEARCH_FORM_FIELDS.CountyCode}
              label="County Name"
              onChange={planOptionsDepsChangeCallback}
              onSuggest={(suggestion) => suggestion}
              rules={[{ required: true, message: 'Please enter a county name.' }]}
            />
              <Form.Item
                name={SEARCH_FORM_FIELDS.Plan}
                label="Insurance Plan"
                rules={[{ required: true, message: 'Please select an insurance plan.' }]}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={handlePlanChange}
                >
                  {planOptions.map(option => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}


            <Form.List
                name="drugs"
                rules={[{
                    message: 'Drug names are duplicate.',
                    validator(_, value) {
                    const matches = value.filter(Boolean);
                    if (matches.length !== new Set(matches).size) {
                        return Promise.reject();
                    }
                    return Promise.resolve();
                    },
                }]}
                >
                {(fields, { add, remove }, { errors }) => (
                    <>
                    {fields.map((field) => (
                        <FormSuggestInput
                        field="drug"
                        label={
                            <Space size="middle">
                            Drug Name
                            {
                                field.key
                                ? (
                                    <span style={{ fontSize: 12, cursor: 'pointer', color: '#8c8c8c' }} onClick={() => remove(field.name)}>
                                    <MinusCircleOutlined style={{ cursor: 'pointer' }} /> Remove this Drug
                                    </span>
                                )
                                : undefined
                            }
                            </Space>
                        }
                        key={field.key}
                        name={field.name}
                        className="form-item-custom" 
                        rules={[{ required: true, message: 'Please enter a drug name.' }]}
                        />
                    ))}
                    <Form.Item>
                        <Form.Item className="form-item-custom">
                        <Button type="dashed" block onClick={() => add('')} icon={<PlusOutlined />}>
                            Add Another Drug
                        </Button>
                        </Form.Item>
                        <Form.ErrorList errors={errors} />
                    </Form.Item>
                    </>
                )}
                </Form.List>


                <Form.Item>
                <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
                    Search
                </Button>
            </Form.Item>
            </Form>
          
          {selectedPlanDetails && (
              <div>
              <p><strong>Searched Plan Name: {selectedPlanDetails.plan_name}</strong></p>
              <div><strong>Plan Annual Deductible:</strong> ${typeof deductible === 'number' ? deductible.toFixed(2) : ''}</div>
            </div>
          )}

          <DrugClassResultsTableMultiDrug
            pagination={{
              pageSize: results.length, // Set page size to the length of results to show all
            }}
            results={results}
            selectedInsurancePlan={selectedPlanDetails.formulary_id}
            selectedPlanDetails={selectedPlanDetails}
            expandedRow={expandedRow}
            setExpandedRow={setExpandedRow}
            handlePopupOpen
          />
        </>
      );
    }