import './index.css';
import { SearchOutlined, CheckCircleOutlined, WarningOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Form, Select, message, Modal, Input, Spin } from 'antd';
import React, { useState, useEffect, useRef } from 'react';
import { api, useAPI } from '../../api';
import DrugClassResultsPage from '../../components/DrugClassResultsPage';
import { debounce } from 'lodash';

const SEARCH_METHOD = {
  BY_PARTD: 'byPartD',
  BY_NAME: 'byName',
};

const SEARCH_FORM_FIELDS = {
  PartDCode: 'partDCode',
  DrugClass: 'drugClass',
  SelectedPlan: 'selectedPlan',
};

const drugClassSelectOptions = [
  {
    label: 'GLP-1 Agonists / GIP GLP-1 Agonists',
    value: 'GLP-1 Receptor Agonist',
  },
  {
    label: 'SGLT2 Inhibitors',
    value: 'Sodium-Glucose Cotransporter 2 Inhibitor',
  },
  {
    label: 'Insulin',
    value: 'insulin',
  },
  {
    label: 'Biguanides',
    value: 'Biguanides',
  },
  {
    label: 'Sulfonylureas',
    value: 'Sulfonylurea Compounds',
  },
  {
    label: 'DPP-4 Inhibitors',
    value: 'Dipeptidyl Peptidase 4 Inhibitor',
  },
  {
    label: 'Thiazolidinediones',
    value: 'Thiazolidinediones',
  },
];

function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

export default function DrugClassSearch() {
  const [form] = Form.useForm();
  const searchInsurancePlansByPartDCode = useAPI(api.searchInsurancePlansByPartDCode);
  const searchPlansByName = useAPI(api.searchPlansByName);
  const searchDrugClassInfoByMultiplePartD = useAPI(api.searchDrugClassInfoByMultiplePartD);
  // const getInsurancePlans = useAPI(api.getInsurancePlans);
  const [planOptions, setPlanOptions] = useState([]);
  // const [results, setResults] = useState([]);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState({});
  // const [expandedRow, setExpandedRow] = useState(null);
  const [, setDeductible] = useState('');
  // const [isModalVisible, setIsModalVisible] = useState(false);
  const [isInstructionsModalVisible, setIsInstructionsModalVisible] = useState(false);
  const [isContactModalVisible, setIsContactModalVisible] = useState(false);
  const [, setSearchMethod] = useState(null);
  // const searchDrugClassInfo = useAPI(api.searchDrugClassInfo);
  const [loading, setLoading] = useState(false);
  const [currentInput, setCurrentInput] = useState('');
  const [isSelectingPlan, setIsSelectingPlan] = useState(false);
  const [resultsByPlan, setResultsByPlan] = useState({});
  const [statusMessage, setStatusMessage] = useState(null);
  const [, setIsPartDSearch] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setDropdownVisible(false));
  const [formLoading, setFormLoading] = useState(false);


  useEffect(() => {
    const plan = form.getFieldValue(SEARCH_FORM_FIELDS.PartDCode);
    if (plan && planOptions.find(option => option.value === plan) === undefined) {
      form.setFieldValue(SEARCH_FORM_FIELDS.PartDCode, '');
    }
  }, [planOptions, form]);

  const handleFormSubmit = async (values) => {
    setFormLoading(true); 
    const drugClass = form.getFieldValue(SEARCH_FORM_FIELDS.DrugClass);
    let selectedOptions = form.getFieldValue(SEARCH_FORM_FIELDS.PartDCode);

    if (!Array.isArray(selectedOptions)) {
      selectedOptions = [selectedOptions];
    }

    let selectedPlans = [];

    if (selectedOptions.includes("search-all")) {
      selectedPlans = planOptions.map(opt => ({
        formulary_id: opt.planDetails.formulary_id,
        contract_id: opt.planDetails.contract_id,
        plan_id: opt.planDetails.plan_id,
        segment_id: opt.planDetails.segment_id,
        plan_name: opt.planDetails.plan_name,
        deductible: opt.planDetails.deductible,
      }));
    } else {
      selectedPlans = selectedOptions.map(option => {
        const found = planOptions.find(opt => opt.value === option.toString());
        return found ? {
          formulary_id: found.planDetails.formulary_id,
          contract_id: found.planDetails.contract_id,
          plan_id: found.planDetails.plan_id,
          segment_id: found.planDetails.segment_id,
          plan_name: found.planDetails.plan_name,
          deductible: found.planDetails.deductible,
        } : undefined;
      }).filter(plan => plan);
    }

    if (selectedPlans.length === 0) {
      message.error('No formulary IDs selected. Please select a plan.');
      setFormLoading(false);
      return;
    }

    try {
      const data = await searchDrugClassInfoByMultiplePartD(drugClass, selectedPlans);
      console.log('API response data:', data); 

      const planResults = {};
      selectedPlans.forEach(plan => {
        const planKey = `${plan.contract_id}_${plan.plan_id}_${plan.segment_id}`;
        const resultsByPlanName = data.results_by_plan[planKey];
        planResults[planKey] = {
          planDetails: plan,
          results: resultsByPlanName ? resultsByPlanName.results : [],
        };
      });

      setResultsByPlan(planResults);
      setStatusMessage(null); // Clear status message after search is done
    } catch (error) {
      console.error('There was an error submitting the form:', error);
      message.error('There was a network error. Please try again later.');
    } finally {
      setFormLoading(false); // Set form loading to false once the results are back
    }
  };

  const processResponse = (response) => {
    if (!Array.isArray(response)) {
      if (response && typeof response === 'object') {
        response = [response];
      } else {
        console.error('Expected an array or an object, but got:', response);
        return [];
      }
    }

    const uniquePlans = [];
    const uniqueKeys = new Set();

    response.forEach(item => {
      const key = `${item.formulary_id}-${item.plan_id}-${item.plan_name}`;
      if (!uniqueKeys.has(key)) {
        uniqueKeys.add(key);
        uniquePlans.push({
          label: `${item.plan_name} (Formulary: ${item.formulary_id}) - ${item.contract_name}`,
          value: `${item.formulary_id}-${item.plan_id}`,
          planDetails: {
            formulary_id: item.formulary_id,
            contract_id: item.contract_id,
            plan_id: item.plan_id,
            segment_id: item.segment_id,
            deductible: item.deductible,
            plan_name: item.plan_name,
          },
        });
      }
    });

    return uniquePlans;
  };

  const fetchPlans = async (query) => {
    console.log("fetchPlans called with query:", query);
    if (isSelectingPlan) {
        console.log("Skipping fetchPlans due to isSelectingPlan being true");
        return;
    }

    if (!query || typeof query !== 'string') {
        setPlanOptions([]);
        setCurrentInput('');
        setSearchMethod(null);
        setStatusMessage(null);
        setDropdownVisible(false);
        return; // No error message, just clear options
    }

    const queryStr = query.toUpperCase().replace(/-/g, '');// Ensure queryStr is defined here
    setCurrentInput(queryStr);
    setLoading(true);

    const isPartDCode = /^[HSR]\d/.test(queryStr);
    setSearchMethod(isPartDCode ? SEARCH_METHOD.BY_PARTD : SEARCH_METHOD.BY_NAME);

    let response = [];

    if (isPartDCode && (queryStr.length === 8 || queryStr.length === 11)) {
        setStatusMessage({ type: 'info', text: 'Searching plan...' });
        response = await searchInsurancePlansByPartDCode(queryStr);
        const formattedPlans = processResponse(response);

        if (formattedPlans.length > 0) {
            setPlanOptions(formattedPlans);
            setStatusMessage({type: 'success', text: 'Plan found'} ); 
            setDropdownVisible(true);
        } else {
            setPlanOptions([]);
            setStatusMessage({ type: 'error', text: 'No plan details found, please re-check your input.' });
            setDropdownVisible(false);
        }
    } else {
        setPlanOptions([]);
        if (isPartDCode) {
            if (queryStr.length < 8) {
                setStatusMessage({ type: 'info', text: 'Please continue input, the PartD code should be length of 8 or 11.' });
            } else if (queryStr.length > 11) {
                setStatusMessage({ type: 'error', text: 'Error: The PartD code should be length of 8 or 11.' });
            } else if (queryStr.length > 8 && queryStr.length < 11) {
                setStatusMessage({ type: 'warning', text: 'The PartD code should be exactly 8 or 11 characters long.' });
            }
        } else {
            setStatusMessage(null);
        }
    }

    if (!isPartDCode) {
        response = await searchPlansByName(queryStr);
        const formattedPlans = processResponse(response);

        if (formattedPlans.length > 0) {
          setPlanOptions(formattedPlans);
          setStatusMessage(null); // Clear status message if plans are found
          setDropdownVisible(true); // Show the dropdown
        } else {
          setPlanOptions([]);
          setStatusMessage({ type: 'error', text: 'No plan details found, please re-check your input.' });
          setDropdownVisible(false); // Hide the dropdown
        }
    }

    setLoading(false);
};

  
  useEffect(() => {
    if (selectedPlanDetails) {
      console.log("Selected plan details updated:", selectedPlanDetails);
    }
  }, [selectedPlanDetails]);

const debouncedFetchPlans = debounce((query) => {
  const queryStr = query.toUpperCase().replace(/-/g, '');
  const isPartDCode = queryStr.length > 1 && /^[HSR]\d+$/.test(queryStr);
  setIsPartDSearch(isPartDCode);
  fetchPlans(query);
}, 300);

useEffect(() => {
  const query = form.getFieldValue(SEARCH_FORM_FIELDS.PartDCode);
  console.log("useEffect triggered with query:", query);
  if (query && typeof query === 'string' && !isSelectingPlan) {
    debouncedFetchPlans(query);
  }
}, [form, debouncedFetchPlans, isSelectingPlan]);

const handleInputChange = (value) => {
  const normalizedValue = value.toUpperCase().replace(/-/g, ''); // Normalize input by removing hyphens
  setCurrentInput(normalizedValue);
  debouncedFetchPlans(normalizedValue);
};

  const handlePlanChange = (value) => {
    setIsSelectingPlan(true);
    console.log("Value received in handlePlanChange:", value);
    console.log("Plan options available:", planOptions);

    if (value.includes('search-all')) {
      console.log("Search all selected");
      setSelectedPlans(['search-all']);
      form.setFieldsValue({ [SEARCH_FORM_FIELDS.PartDCode]: ['search-all'] });
    } else {
      setSelectedPlans(value);
      console.log("Set selected plans to:", value);

      const actualValue = Array.isArray(value) ? value[0] : value;
      console.log("Actual value to find in planOptions:", actualValue);
      const selectedOption = planOptions.find(opt => opt.value === actualValue);
      console.log("Handling plan change:", selectedOption);

      if (selectedOption) {
        setSelectedPlanDetails(selectedOption.planDetails);
        setDeductible(selectedOption.planDetails.deductible);
        console.log("Setting form field value to:", value);
        form.setFieldsValue({ [SEARCH_FORM_FIELDS.PartDCode]: value });
      } else {
        console.error('Selected plan details not found.');
        setSelectedPlanDetails({});
        setDeductible(undefined);
        form.setFieldsValue({ [SEARCH_FORM_FIELDS.PartDCode]: '' });
      }
    }
    setStatusMessage(null); // Clear status message once a plan is selected
    setIsSelectingPlan(false);
  };

  const handleInstructionsModalCancel = () => {
    setIsInstructionsModalVisible(false);
  };

  const handleContactModalSubmitPartD = async (values) => {
    try {
      const response = await fetch('/send-contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      const data = await response.json();

      if (response.ok) {
        message.success('Your request has been submitted. We will contact you shortly.');
        setIsContactModalVisible(false);
      } else {
        message.error(data.message || 'An error occurred. Please try again later.');
      }
    } catch (error) {
      console.error('There was an error submitting the form:', error);
      message.error('There was a network error. Please try again later.');
    }
  };

  const handleContactModalCancel = () => {
    setIsContactModalVisible(false);
  };

  const showContactModal = () => {
    setIsInstructionsModalVisible(false);
    setIsContactModalVisible(true);
  };

  const generateUniqueKey = (option) => `${option.value}-${option.planDetails.contract_id}`;

  const renderStatusMessage = () => {
    if (!statusMessage) return null;
  
    const { type, text } = statusMessage;
    let icon;
    let style;
  
    switch (type) {
      case 'success':
        icon = <CheckCircleOutlined style={{ color: 'green' }} />;
        style = { color: 'green' };
        break;
      case 'warning':
        icon = <WarningOutlined style={{ color: 'orange' }} />;
        style = { color: 'orange' };
        break;
      case 'error':
        icon = <ExclamationCircleOutlined style={{ color: 'red' }} />;
        style = { color: 'red' };
        break;
      case 'validating':
        icon = <ExclamationCircleOutlined style={{ color: 'blue' }} />;
        style = { color: 'blue' };
        break;
      case 'info':
        icon = null;
        style = { color: 'grey' };
        break;
      default:
        icon = null;
    }
  
    return (
      <div className="status-message" style={style}>
        {icon} {text}
      </div>
    );
  };
  

  return (
    <div ref={wrapperRef}>
      <Button
        onClick={showContactModal}
        className="floating-help-button"
        style={{
          marginBottom: 16,
          backgroundColor: '#f5f5f5',
          color: 'black',
          borderColor: '#f5f5f5',
        }}
      >
        <strong>Ask for Help</strong>
      </Button>

      <Form form={form} layout="vertical" onFinish={handleFormSubmit} style={{ maxWidth: 600 }}>
      {renderStatusMessage()}

      {formLoading && (
      <div className="loading-overlay">
        <Spin tip="Searching, please wait..." />
      </div>
      )}

        <Form.Item
          name={SEARCH_FORM_FIELDS.PartDCode}
          label="PartD Code or Plan Name"
          rules={[{ required: true, message: 'Please enter the PartD Code or Plan Name.' }]}
        >
          <Select
            showSearch
            mode="multiple"
            placeholder="Enter PartD code or Plan name"
            onSearch={handleInputChange}
            onChange={handlePlanChange}
            maxTagCount={3}
            filterOption={false}
            loading={loading}
            allowClear
            onClear={() => {
              setPlanOptions([]);
              setSelectedPlans([]);
              form.resetFields([SEARCH_FORM_FIELDS.PartDCode]);
              setDropdownVisible(false); 
            }}
            value={selectedPlans}
            // dropdownClassName="custom-dropdown"
            options={planOptions.length ? [{
              label: `Search All "${currentInput}"`,
              key: 'search-all',
              value: 'search-all',
            }].concat(planOptions.map(option => ({
              label: option.label,
              value: option.value,
              key: generateUniqueKey(option),
            }))) : []}
            dropdownVisible={dropdownVisible}
            onDropdownVisibleChange={setDropdownVisible}
          />
          <div className="partd-example">
            Example: H6158-001 or H6158-001-002. You can input with or without '-'.
          </div>
        </Form.Item>
        <Modal
          title="How to Find PartD/Plan Benefits Code"
          visible={isInstructionsModalVisible}
          onCancel={handleInstructionsModalCancel}
          footer={[
            <Button key="back" onClick={handleInstructionsModalCancel}>
              Close
            </Button>,
          ]}
        >
          <ul>
            <li>Check insurance card for a code starts with a letter (H, R, or S) followed by a series of 7 or 10 digits.</li>
            <li>Example codes might look like <strong>H6158-001</strong> or <strong>H6158-001-002</strong>.</li>
            <li>If you cannot find the code, <button type="button" onClick={showContactModal} className="link-button" style={{ padding: 0, backgroundColor: 'transparent', border: 'none', color: '#007bff', textDecoration: 'underline' }}>contact us</button> and we will help you find it</li>
            <img src="https://i.imgur.com/ovIpEVp.jpeg" alt="Example PartD Code" style={{ maxWidth: '100%', marginTop: '20px' }} />
          </ul>
        </Modal>
        <Modal
          title="Contact Us for Help"
          visible={isContactModalVisible}
          onCancel={handleContactModalCancel}
          footer={null}
        >
          <Form onFinish={handleContactModalSubmitPartD}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'Please input your name!' }]}
            >
              <Input placeholder="Your Name" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
            >
              <Input placeholder="Your Email" />
            </Form.Item>
            <Form.Item
              name="message"
              rules={[{ required: true, message: 'Please provide insurance details or patient info.' }]}
            >
              <Input.TextArea
                placeholder="Provide any information you have about the patient's insurance, or provide the patient's name and date of birth and we will assist you in finding it."
                rows={4}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Form.Item
          label="Drug Class"
          name={SEARCH_FORM_FIELDS.DrugClass}
          rules={[{ required: true, message: 'Please select a drug class.' }]}
        >
          <Select options={drugClassSelectOptions} />
        </Form.Item>
        <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
          Search
        </Button>
      </Form>
      {Object.keys(resultsByPlan).length > 0 && (
        <DrugClassResultsPage resultsByPlan={resultsByPlan} />
      )}
    </div>
  );
}
