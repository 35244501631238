import './index.css';
import { CaretUpOutlined, CaretDownOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, Table, Select, Button, Tooltip } from 'antd'; 
import React, { useState, useEffect } from 'react';
import DrugCoverageSummary from '../../components/DrugCoverageSummary';
// import { Link } from 'react-router-dom';
// import { api, useAPI } from '../../api';

const drugsWithPatientAssistance = [
  { name: "BYDUREON Bcise", url: "https://www.azandmeapp.com/prescriptionsavings/?screenName=showEnrollmentPage" },
  { name: "Trulicity", url: "https://www.lillycares.com/how-to-apply#check-eligibility" },
  { name: "Ozempic", url: "https://www.novocare.com/diabetes/help-with-costs/pap.html" },
  { name: "RYBELSUS", url: "https://www.novocare.com/diabetes/help-with-costs/pap.html" },
  { name: "Soliqua 100/33", url: "https://www.sanofipatientconnection.com/media/pdf/SPC_Application.pdf" },
  { name: "Xultophy 100/3.6", url: "https://www.novocare.com/diabetes/help-with-costs/pap.html" },
  { name: "Jentadueto", url: "https://www.boehringer-ingelheim.com/us/about-us/sustainable-development/our-commitment/bi-cares-patient-assistance-portal" },
  { name: "Jentadueto XR", url: "https://www.boehringer-ingelheim.com/us/about-us/sustainable-development/our-commitment/bi-cares-patient-assistance-portal" },
  { name: "Glyxambi", url: "https://www.boehringer-ingelheim.com/us/about-us/sustainable-development/our-commitment/bi-cares-patient-assistance-portal" },
  { name: "JANUMET", url: "https://www.merckhelps.com/JANUMET" },
  { name: "JANUMET XR", url: "https://www.merckhelps.com/JANUMET" },
  { name: "KAZANO", url: "https://www.helpathandpap.com/About.aspx" },
  { name: "OSENI", url: "https://www.helpathandpap.com/About.aspx" },
  { name: "Jardiance", url: "https://www.boehringer-ingelheim.com/us/our-responsibility/patient-assistance-program" },
  { name: "Synjardy", url: "https://www.boehringer-ingelheim.com/us/our-responsibility/patient-assistance-program" },
  { name: "FARXIGA", url: "https://www.azandmeapp.com/prescriptionsavings/?screenName=showEnrollmentPage" },
  { name: "Trijardy XR", url: "https://www.boehringer-ingelheim.com/us/our-responsibility/patient-assistance-program" },
  { name: "XIGDUO", url: "https://www.azandmeapp.com/prescriptionsavings/?screenName=showEnrollmentPage" },
  { name: "INVOKAMET", url: "https://www.jjpaf.org/eligibility/?medication=INVOKAMET%C2%AE#step1" },
  { name: "INVOKAMET XR", url: "https://www.jjpaf.org/eligibility/?medication=INVOKAMET%C2%AE%20XR#step1" },
  { name: "Fiasp", url: "https://www.novocare.com/diabetes/help-with-costs/pap.html" },
  { name: "LYUMJEV", url: "https://www.lillycares.com/how-to-apply#choose-application" },
  { name: "NOVOLOG", url: "https://www.novocare.com/diabetes/help-with-costs/pap.html" },
  { name: "Humalog", url: "https://www.lillycares.com/how-to-apply#choose-application" },
  { name: "ADMELOG", url: "https://www.sanofipatientconnection.com/media/pdf/SPC_Application.pdf" },
  { name: "Apidra", url: "https://www.sanofipatientconnection.com/media/pdf/SPC_Application.pdf" },
  { name: "Levemir", url: "https://www.novocare.com/diabetes/help-with-costs/pap.html" },
  { name: "Lantus", url: "https://www.sanofipatientconnection.com/patient-assistance-connection" },
  { name: "BASAGLAR", url: "https://www.lillycares.com/how-to-apply#choose-application" },
  { name: "SEMGLEE", url: "https://www.bioconbiologicsus.com/pap" },
  { name: "Rezvoglar", url: "https://www.lillycares.com/how-to-apply#choose-application" },
  { name: "Tresiba", url: "https://www.novocare.com/diabetes/help-with-costs/pap.html" },
  { name: "TOUJEO", url: "https://www.sanofipatientconnection.com/patient-assistance-connection" },
  { name: "TOUJEO Max", url: "https://www.sanofipatientconnection.com/patient-assistance-connection" },
  { name: "Humulin", url: "https://www.lillycares.com/how-to-apply#choose-application" },
  { name: "NovoLog Mix 70/30", url: "https://www.novocare.com/diabetes/help-with-costs/pap.html" },
  { name: "AFREZZA", url: "https://afrezza.com/paying-for-afrezza/" },
  { name: "Insulin Lispro Protamine and Insulin Lispro Injectable Suspension", url: "https://www.lillycares.com/how-to-apply#choose-application" },
].map(drug => ({ ...drug, name: drug.name.toLowerCase() }));

// async function fetchPharmacies({ drugName, zipCode, contractId, planId, segmentId }) {
//   try {
//     const response = await fetch('/drug_lookup', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         contract_id: contractId,
//         plan_id: planId,
//         segment_id: segmentId,
//         Drug_name: drugName,
//         Zip_Code: zipCode,
//       }),
//     });

//     if (!response.ok) {
//       throw new Error('Network response was not ok');
//     }

//     const pharmacies = await response.json();
//     return pharmacies;
//   } catch (error) {
//     console.error('Error fetching pharmacies:', error);
//     return []; // Return an empty array in case of an error
//   }
// }

// async function fetchPharmacyCostDetails({ pharmacyName, zipCode, drugName, contractId, planId, segmentId }) {
//   try {
//     const response = await fetch('/pharmacy_selection', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         pharmacy_name: pharmacyName,
//         Drug_name: drugName,
//         Zip_Code: zipCode,
//         contract_id: contractId,
//         plan_id: planId,
//         segment_id: segmentId,
//       }),
//     });

//     if (response.ok) {
//       const costDetails = await response.json();
//       return costDetails;
//     } else {
//       console.error(`Error fetching pharmacy cost details: Status code ${response.status}`);
//       const errorResponse = await response.text();
//       console.error('Error response:', errorResponse);
//       return {}; // Return an empty object or handle error as appropriate
//     }
//   } catch (error) {
//     console.error('Network error when fetching pharmacy cost details:', error);
//     throw error; // Rethrow the error to be handled by the caller
//   }
// }

// const formatCurrency = (amount) => {
//   // Check if the amount is not null or undefined
//   if (amount == null) {
//     return 'N/A';
//   }
//   return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
// };

// const MonthlyCosts = ({ monthlyCosts, showMonthlyCosts }) => {
//   const orderedMonths = [
//     'January', 'February', 'March', 'April', 'May', 'June',
//     'July', 'August', 'September', 'October', 'November', 'December'
//   ];

//   // Filter and map the months to sort them
//   return orderedMonths
//     .filter(month => monthlyCosts.hasOwnProperty(month.toLowerCase()))
//     .map(month => (
//       <p key={month} style={{ display: showMonthlyCosts ? 'block' : 'none' }}>
//         - {month}: {formatCurrency(monthlyCosts[month.toLowerCase()])}
//       </p>
//     ));
// };

// const PharmaciesCostDetailsTable = ({ pharmacyDetails }) => {
//   // Generate the base columns for the table, these will be static
//   const columns = [
//     {
//       title: 'Cost Detail',
//       dataIndex: 'detail',
//       key: 'detail',
//       fixed: 'left',
//       width: 100,
//     },
//     // ... potentially more static columns
//   ];

//   // Add a column for each pharmacy
//   pharmacyDetails.forEach((pharmacy, index) => {
//     columns.push({
//       title: pharmacy.pharmacy_name,
//       children: [
//         {
//           title: 'Cost before Deductible',
//           dataIndex: `costBeforeDeductible_${index}`,
//           key: `costBeforeDeductible_${index}`,
//           render: (text) => formatCurrency(text),
//         },
//         {
//           title: 'Cost after Deductible',
//           dataIndex: `costAfterDeductible_${index}`,
//           key: `costAfterDeductible_${index}`,
//           render: (text) => formatCurrency(text),
//         },
//         // ... more sub-columns for other costs
//         {
//           title: 'Monthly Costs',
//           dataIndex: `monthlyCosts_${index}`,
//           key: `monthlyCosts_${index}`,
//           render: (text) => text.map((cost, monthIndex) => (
//             <div key={monthIndex}>
//               {formatCurrency(cost)}
//             </div>
//           )),
//         },
//       ],
//     });
//   });

//   // Generate data source for the table
//   const dataSource = pharmacyDetails.map((pharmacy, index) => ({
//     key: index,
//     detail: pharmacy.pharmacy_name, // Assuming you want to display the pharmacy name here
//     ...pharmacy, // Spread all the pharmacy details into the data source object
//   }));

//   return (
//     <Table 
//       columns={columns}
//       dataSource={dataSource}
//       pagination={false}
//       bordered
//       scroll={{ x: '100vw' }} // Adjust scroll to your needs
//       size="small" // Use size "small" for compact rows
//     />
//   );
// };

// const PharmacyCostDetails = ({ costDetails, selectedPharmacy, selectedPlanDetails }) => {
//   const [showMonthlyCosts, setShowMonthlyCosts] = useState(false);
  
//   console.log('Selected Pharmacy:', selectedPharmacy);
//   if (!costDetails) {
//     return <p>Select a pharmacy to see cost details</p>;
//   }

//   // Destructure with default values to avoid accessing properties on undefined
//   const {
//     Cost_after_coverage_gap = null,
//     Cost_after_deductible = null,
//     Cost_before_deductible = null,
//     Cost_in_coverage_gap = null,
//     Timeline = {},
//     Monthly_costs = {},
//     Total_yearly_drug_cost = null,
//   } = costDetails;

//   const orderedMonths = [
//     'January', 'February', 'March', 'April', 'May', 'June',
//     'July', 'August', 'September', 'October', 'November', 'December'
//   ];

//   // Filter and map the months to sort them
//   const sortedMonthlyCosts = orderedMonths
//     .filter(month => Monthly_costs.hasOwnProperty(month))
//     .map(month => (
//       <p key={month}>- {month}: {formatCurrency(Monthly_costs[month])}</p>
//     ));
  
//   const pharmacyName = selectedPharmacy?.[0]?.pharmacy_name;
//   const formattedPlanDeductible = formatCurrency(selectedPlanDetails?.deductible ?? 0);
  
//   return (
//     <div>
//       {selectedPharmacy && (
//         <h3>Pharmacy: {pharmacyName}</h3>
//       )}
//       <h3>Plan Deductible: {formattedPlanDeductible}</h3>
//       <h3>Cost Details</h3>
//       <p>Cost before Deductible: {formatCurrency(Cost_before_deductible)}</p>
//       <p>Cost after Deductible: {formatCurrency(Cost_after_deductible)}</p>
//       <p>Cost in Coverage Gap: {formatCurrency(Cost_in_coverage_gap)}</p>
//       <p>Cost after Coverage Gap: {formatCurrency(Cost_after_coverage_gap)}</p>
      
      
//       <h3>Timeline</h3>
//       <p>Starting Month: {Timeline.Starting_month}</p>
//       <p>Meet Deductible: {Timeline.Meet_Deductible}</p>
//       <p>Enter Coverage Gap: {Timeline.Enter_coverage_gap}</p>
//       <p>Get Out of Coverage Gap: {Timeline.Get_out_of_coverage_gap}</p>
//       <p>Total Yearly Drug Cost: {formatCurrency(Total_yearly_drug_cost)}</p>
      
//       <h3 onClick={() => setShowMonthlyCosts(!showMonthlyCosts)} style={{ cursor: 'pointer' }}>
//         Monthly Costs {showMonthlyCosts ? '(Hide)' : '(Show)'}
//       </h3>
//       {showMonthlyCosts && sortedMonthlyCosts}
//     </div>
//   );
// };

export function renderRequirements(drug, selectedInsurancePlan, handlePopupOpen, sendEmailNotification) {
  const requirements = Array.isArray(drug.requirements) ? drug.requirements : [drug.requirements];
  if (drug.STEP_THERAPY_YN === 'Y') {
    requirements.push('Step Therapy required');
  }
  const quantityDetails = requirements.find(req => req.startsWith('Quantity Limit Amount')) || '';

  return (
    <div>
      {requirements.map((requirement, index) => {
      if (requirement === 'Prior Authorization required') {
        return (
          <div key={index}>
            {requirement}
            <span onClick={() => {
              const popupContent = 'Prior Authorization Function is Working-in-progress';
              // Call handlePopupOpen with the popupContent, but do not send the email yet
              handlePopupOpen(drug.proprietaryname, popupContent);
            }} style={{ cursor: 'pointer', marginLeft: '5px', color: 'blue' }}>
              (Check)
            </span>
          </div>
        );
        } else if (requirement === 'Quantity Limits apply') {
          return (
            <div key={index}>
              {requirement}
              <span onClick={() => handlePopupOpen(drug.proprietaryname, quantityDetails)}
                style={{ cursor: 'pointer', marginLeft: '5px', color: 'blue' }}> {/* Color set to blue here */}
                (Details)
              </span>
            </div>
          );
        } else if (requirement.startsWith('Quantity Limit Amount')) {
          // This line is intentionally not rendered since its details are in the popup
          return null;
        } else {
          return <div key={index}>{requirement}</div>;
        }
      })}
    </div>
  );
}

const sendEmailNotification = async (messageDetails) => {
  try {
    const response = await fetch('/send-notification-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(messageDetails),
    });

    const data = await response.json();
  
    if (response.ok) {
      console.log('Email notification sent successfully:', data);
    } else {
      console.error('Failed to send email notification:', data.message);
    }
  } catch (error) {
    console.error('Error sending email notification:', error);
  }
};

// function getFirstRowByProprietaryName(drugs) {
//   const selected = new Set();
//   const results = [];
//   for (const drug of drugs) {
//     const name = drug.proprietaryname.toLowerCase();
//     if (selected.has(name)) {
//       continue;
//     }
//     selected.add(name);
//     results.push(drug);
//   }
//   return results;
// }

// function getProprietaryNameExpandedRows(record, drugs) {
//   const match = record.proprietaryname.toLowerCase();
//   return drugs.filter(drug => {
//     return drug.proprietaryname.toLowerCase() === match;
//   });
// }
function getProprietaryNameExpandedRows(nonproprietaryname, groupedResults) {
  const group = groupedResults.find(group => group.nonproprietaryname === nonproprietaryname);
  return group ? group.details : [];
}


const proprietaryNameDetailColumns = (
) => [
  {
    dataIndex: 'proprietaryname',
    title: 'Description',
  },
  {
    dataIndex: 'tier_level_value',
    title: 'Tier Level Value',
    render: (tierLevelValue) => {
      return (
        <span>
          {tierLevelValue}
        </span>
      );
    },
  },
];

const processAndDisplayBeneficiaryCosts = (beneficiaryCostData, daysSupplyFilter, pharmacyType) => {
  
  if (!Array.isArray(beneficiaryCostData)) {
    return null;
  }

  const daysSupplyCondition = daysSupplyFilter === '30days' ? 1 : 2;
  const hasCoverageGap = beneficiaryCostData.some(cost => cost.coverage_level === 2 && cost.days_supply === daysSupplyCondition);
  if (!hasCoverageGap) {
    const coverageGapEntry = {
      coverage_level: 2,
      days_supply: daysSupplyCondition,
      cost_type_pref: 2, 
      cost_type_nonpref: 2, 
      cost_type_mail_pref: 2, 
      cost_type_mail_nonpref: 2, 
      cost_amt_pref: 0.25, 
      cost_amt_nonpref: 0.25,
      cost_amt_mail_pref: 0.25,
      cost_amt_mail_nonpref: 0.25
    };
    beneficiaryCostData.push(coverageGapEntry);
  }
  
  beneficiaryCostData.sort((a, b) => a.coverage_level - b.coverage_level);
  const filteredCosts = beneficiaryCostData.filter(cost => cost.days_supply === daysSupplyCondition);

// Format and create table rows
  const tableRows = filteredCosts.map((cost, index) => {
    const coverageWord = getCoverageLevelWord(cost.coverage_level);

    const actualCostPref = cost.cost_type_pref === 0 ? cost.cost_amt_nonpref : cost.cost_amt_pref;
    const actualCostNonPref = cost.cost_amt_nonpref;
    
    const preferredCostFormatted = formatCost(cost.cost_type_pref, actualCostPref);
    const nonPreferredCostFormatted = formatCost(cost.cost_type_nonpref, actualCostNonPref);

    return (
      <tr key={index}>
        <td>{coverageWord}</td>
        <td>{preferredCostFormatted}</td>
        <td>{nonPreferredCostFormatted}</td>
      </tr>
    );
  });
  
  return (
    <table>
      <thead>
        <tr>
          <th>Coverage Level</th>
          <th>Preferred Pharmacy Cost</th>
          <th>Nonpreferred Pharmacy Cost</th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </table>
  );
};

const formatCost = (costType, amount) => {
  if (amount === undefined || amount === null || amount === 'N/A') {
    return 'N/A';
  }

  // Handle the special case where costType is 0
  if (costType === 0) {
    // If amount is a string, it's likely a percentage
    if (typeof amount === 'string' && amount.includes('%')) {
      return amount;
    }
    // If amount is numeric, format it as currency
    return `$${parseFloat(amount).toFixed(2)}`;
  }

  // Handle the default coverage gap cost which is a percentage
  if (costType === 2 && parseFloat(amount) === 0.25) {
    return '25%'; // Default coverage gap cost
  }

  // Format the amount as currency for costType 1
  if (costType === 1) {
    return `$${parseFloat(amount).toFixed(2)}`;
  }

  // If costType is 2 but not the default coverage gap, format as percentage
  if (costType === 2) {
    return `${(parseFloat(amount) * 100).toFixed(0)}%`;
  }

  // Return the amount as is for any other case
  return amount;
};

export const SupplyDurationSelector = ({ value, onChange }) => {
  return (
    <Select value={value} style={{ width: 120 }} onChange={onChange}>
      <Select.Option value="30days">30 Days</Select.Option>
      <Select.Option value="90days">90 Days</Select.Option>
    </Select>
  );
};

export const PharmacyTypeSelector = ({ value, onChange }) => {
  return (
    <Select value={value} style={{ width: 160 }} onChange={onChange}>
      <Select.Option value="retail">Retail Pharmacy</Select.Option>
      <Select.Option value="mail">Mail Pharmacy</Select.Option>
    </Select>
  );
};

const processAndDisplayInsulinCosts = (insulinCostData, daysSupplyFilter, pharmacyType) => {
  if (!Array.isArray(insulinCostData)) {
    return null; // Or some placeholder indicating no data is available
  }

  const coverageLevels = {
    '1': '1. Initial Coverage',
    '2': '2. Coverage Gap',
    '3': '3. Catastrophic',
  };

  const daysSupplyCondition = daysSupplyFilter === '30days' ? 1 : 2;

  let coverageInfo = [
    { level: '1', preferredCost: 'N/A', nonPreferredCost: 'N/A' }, // Assume 'N/A' by default
    { level: '2', preferredCost: 'N/A', nonPreferredCost: 'N/A' }, // Assume 'N/A' by default
    { level: '3', preferredCost: 0, nonPreferredCost: 0 }, // Catastrophic coverage with $0 cost
  ];

  insulinCostData.forEach(cost => {
    if (cost.days_supply === daysSupplyCondition) {
      let preferredCost = pharmacyType === 'retail' ? cost.copay_amt_pref_insln : cost.copay_amt_mail_pref_insln;
      let nonPreferredCost = pharmacyType === 'retail' ? cost.copay_amt_nonpref_insln : cost.copay_amt_mail_nonpref_insln;

      preferredCost = preferredCost === null ? 'N/A' : preferredCost.toString();
      nonPreferredCost = nonPreferredCost === null ? 'N/A' : nonPreferredCost.toString();

      coverageInfo[0].preferredCost = preferredCost;
      coverageInfo[0].nonPreferredCost = nonPreferredCost;
      coverageInfo[1].preferredCost = preferredCost; // Assuming coverage gap has the same cost
      coverageInfo[1].nonPreferredCost = nonPreferredCost;
    }
  });

  // Adjust level 3 costs based on levels 1 and 2
  if (coverageInfo[0].preferredCost === 'N/A' && coverageInfo[1].preferredCost === 'N/A') {
    coverageInfo[2].preferredCost = 'N/A';
  }
  if (coverageInfo[0].nonPreferredCost === 'N/A' && coverageInfo[1].nonPreferredCost === 'N/A') {
    coverageInfo[2].nonPreferredCost = 'N/A';
  }

  // Map the coverageInfo to table rows
  const tableRows = coverageInfo.map((info, index) => {
    const coverageWord = coverageLevels[info.level];
    const formattedPreferredCost = info.preferredCost !== 'N/A' ? `$${parseFloat(info.preferredCost).toFixed(2)}` : 'N/A';
    const formattedNonPreferredCost = info.nonPreferredCost !== 'N/A' ? `$${parseFloat(info.nonPreferredCost).toFixed(2)}` : 'N/A';

    return (
      <tr key={index}>
        <td>{coverageWord}</td>
        <td>{formattedPreferredCost}</td>
        <td>{formattedNonPreferredCost}</td>
      </tr>
    );
  });

  return (
    <table>
      <thead>
        <tr>
          <th>Coverage Level</th>
          <th>Preferred Pharmacy Cost</th>
          <th>Nonpreferred Pharmacy Cost</th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </table>
  );
};

const processAndDisplayInsulinCostNew = (insulinCostData, daysSupplyFilter, pharmacyType) => {
  if (!Array.isArray(insulinCostData)) {
    return 'No insulin cost data available'; // Placeholder for no data
  }

  const daysSupplyValue = daysSupplyFilter === '30days' ? 1 : (daysSupplyFilter === '90days' ? 2 : 1);
  const coverageLevels = {
    0: '0. Deductible Stage',
    1: '1. Initial Coverage',
    2: '2. Coverage Gap',
    3: '3. Catastrophic',
  };

  let coverageInfo = [
    { level: '0', preferredCost: 'N/A', nonPreferredCost: 'N/A' },
    { level: '1', preferredCost: 'N/A', nonPreferredCost: 'N/A' }, // Assume 'N/A' by default
    { level: '2', preferredCost: 'N/A', nonPreferredCost: 'N/A' }, // Assume 'N/A' by default
    { level: '3', preferredCost: 0, nonPreferredCost: 0 }, // Catastrophic coverage with $0 cost
  ];

  insulinCostData.forEach(cost => {
    if (cost.days_supply === daysSupplyValue) {
      let preferredCost, nonPreferredCost;
      if (pharmacyType === 'retail') {
        preferredCost = cost.copay_amt_pref_insln !== null ? cost.copay_amt_pref_insln : cost.copay_amt_nonpref_insln;
        nonPreferredCost = cost.copay_amt_nonpref_insln;
      } else { // Handling for mail pharmacy
        preferredCost = cost.copay_amt_mail_pref_insln !== null ? cost.copay_amt_mail_pref_insln : cost.copay_amt_mail_nonpref_insln;
        nonPreferredCost = cost.copay_amt_mail_nonpref_insln;
      }

      preferredCost = (preferredCost !== null && preferredCost !== undefined) ? preferredCost.toString() : 'N/A';
      nonPreferredCost = (nonPreferredCost !== null && nonPreferredCost !== undefined) ? nonPreferredCost.toString() : 'N/A';
      
      coverageInfo[0].preferredCost = preferredCost;
      coverageInfo[0].nonPreferredCost = nonPreferredCost;
      coverageInfo[1].preferredCost = preferredCost;
      coverageInfo[1].nonPreferredCost = nonPreferredCost;
      coverageInfo[2].preferredCost = preferredCost; // Assuming coverage gap has the same cost
      coverageInfo[2].nonPreferredCost = nonPreferredCost;
    }
  });

  // Adjust level 3 costs based on levels 1 and 2
  if (coverageInfo[1].preferredCost === 'N/A' && coverageInfo[2].preferredCost === 'N/A') {
    coverageInfo[3].preferredCost = 'N/A';
  }
  if (coverageInfo[1].nonPreferredCost === 'N/A' && coverageInfo[2].nonPreferredCost === 'N/A') {
    coverageInfo[3].nonPreferredCost = 'N/A';
  }

  // Map the coverageInfo to table rows, excluding non-preferred pharmacy costs
  const tableRows = coverageInfo.map((info, index) => {
    const coverageWord = coverageLevels[info.level];
    const formattedPreferredCost = info.preferredCost !== 'N/A' ? `$${parseFloat(info.preferredCost).toFixed(2)}` : 'N/A';
    
    return (
      <tr key={index}>
        <td>{coverageWord}</td>
        <td>{formattedPreferredCost}</td>
      </tr>
    );
  });

  return (
    <table>
      <thead>
        <tr>
          <th>Coverage Level</th>
          <th>Preferred Pharmacy Cost</th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </table>
  );
};

export const getCoverageLevelWord = (level) => {
  const coverageLevels = {
    0: '0. Pre-Deductible',
    1: '1. Initial Coverage',
    2: '2. Coverage Gap',
    3: '3. Catastrophic',
  };
  return coverageLevels[level] || 'Unknown';
};
const patientOutOfPocketCostRender = (record, selectedDaysSupply, selectedPharmacyType, deductible) => {
  // console.log('Deductible:', deductible);

  if (!Array.isArray(record.out_of_pocket_cost)) {
    return 'No cost data available';
  }

  const daysSupplyValue = selectedDaysSupply === '30days' ? 1 : 2;
  const isInsulin = record.out_of_pocket_cost.some(cost => cost.coverage_level === undefined);
  let costHeaderSuffix = selectedPharmacyType === 'retail' ? 'Pharmacy' : 'Mail Pharmacy';
  
  const costHeader = `Preferred ${costHeaderSuffix} Cost / ${selectedDaysSupply.replace('days', ' Days')}`;

  const mailOrderNotOffered = record.out_of_pocket_cost.some(cost =>
    cost.days_supply === daysSupplyValue &&
    cost.cost_amt_mail_pref === 'not offered' &&
    cost.cost_amt_mail_nonpref === 'not offered'
  );

  // If the mail order benefit is not offered, display the message and do not render the rest of the table
  if (mailOrderNotOffered && selectedPharmacyType === 'mail') {
    return <p>The plan doesn’t offer a mail order benefit.</p>;
  }

  if (deductible > 0 && !record.out_of_pocket_cost.some(cost => cost.coverage_level === 0 && cost.days_supply === daysSupplyValue)) {
    const deductibleStageEntry = {
      coverage_level: 0,
      days_supply: daysSupplyValue,
      cost_type_pref: 2, // This could be a special indicator for deductible stage.
      cost_type_nonpref: 2, // Assuming the same applies for non-preferred.
      cost_type_mail_pref: 2, // Assuming the same applies for mail preferred.
      cost_type_mail_nonpref: 2, // Assuming the same applies for mail non-preferred.
      cost_amt_pref: '1.00', // 100% cost to patient during the deductible stage.
      cost_amt_nonpref: '1.00', // Assuming 100% for non-preferred as well.
      cost_amt_mail_pref: '1.00', // Assuming 100% for mail order preferred.
      cost_amt_mail_nonpref: '1.00' // Assuming 100% for mail order non-preferred.
    };
    record.out_of_pocket_cost.unshift(deductibleStageEntry);
  }

  // If the drug is insulin, process using insulin-specific logic
  if (isInsulin) {
    return processAndDisplayInsulinCostNew(record.out_of_pocket_cost, selectedDaysSupply, selectedPharmacyType);
  }
    const hasCoverageGap = record.out_of_pocket_cost.some(cost => cost.coverage_level === 2 && cost.days_supply === daysSupplyValue);
    if (!hasCoverageGap) {
      const coverageGapEntry = {
        coverage_level: 2,
        days_supply: daysSupplyValue,
        cost_type_pref: 2, 
        cost_type_nonpref: 2, 
        cost_type_mail_pref: 2, 
        cost_type_mail_nonpref: 2, 
        cost_amt_pref: 0.25, 
        cost_amt_nonpref: 0.25,
        cost_amt_mail_pref: 0.25,
        cost_amt_mail_nonpref: 0.25
      };
      record.out_of_pocket_cost.push(coverageGapEntry);
    }
    
    // Filter costs based on days supply and coverage level, assuming the array might not be sorted by coverage level
    const sortedAndFilteredCosts = record.out_of_pocket_cost
      .filter(cost => cost.days_supply === daysSupplyValue)
      .sort((a, b) => a.coverage_level - b.coverage_level);

    // console.log('Sorted and filtered costs:', sortedAndFilteredCosts);

    // If there's no data after filtering, return a message or a default UI element
    if (sortedAndFilteredCosts.length === 0) {
      return 'No cost data available for the selected options';
    }

    const tableRows = sortedAndFilteredCosts.map((cost, index) => {
      const coverageWord = getCoverageLevelWord(cost.coverage_level);
      let pharmacyKey;
      if (cost.coverage_level === 0) {
        pharmacyKey = 'cost_amt_pref'; // Deductible stage always uses preferred cost
      } else {
        pharmacyKey = selectedPharmacyType === 'retail' ? 'cost_amt_pref' : 'cost_amt_mail_pref';
      }
      const mailOrderNotOffered = (pharmacyKey.includes('mail') && 
                                      cost.cost_amt_mail_pref === 'not offered' &&
                                      cost.cost_amt_mail_nonpref === 'not offered');

      let costFormatted;
      if (mailOrderNotOffered) {
      costFormatted = "The plan doesn’t offer a mail order benefit";
      } else {
      costFormatted = formatCost(cost.cost_type_pref, cost[pharmacyKey]);
      }

      if (index === 0) {
      // console.log('First cost entry:', cost);
      // console.log('First row display:', `${coverageWord}: ${costFormatted}`);
      }

      return (
      <tr key={index}>
      <td>{coverageWord}</td>
      <td>{costFormatted}</td>
      </tr>
      );
      });

      return (
      <table>
      <thead>
      <tr>
      <th>Coverage Level</th>
      <th>{costHeader}</th> {/* Updated header */}
      </tr>
      </thead>
      <tbody>{tableRows}</tbody>
      </table>
      );
};

const tierLevelDescriptions = {
  1: 'Preferred Generic Drug',
  2: 'Generic Drug',
  3: 'Preferred Brand Drug',
  4: 'Non-Preferred Drug',
  5: 'Specialty Tier Drug',
  6: 'Select Care Drug',
};

const tierTooltipContent = (
  <div>
    <p>Tier 1: {tierLevelDescriptions[1]}</p>
    <p>Tier 2: {tierLevelDescriptions[2]}</p>
    <p>Tier 3: {tierLevelDescriptions[3]}</p>
    <p>Tier 4: {tierLevelDescriptions[4]}</p>
    <p>Tier 5: {tierLevelDescriptions[5]}</p>
    <p>Tier 6: {tierLevelDescriptions[6]}</p>
  </div>
);

const resultTableColumns = (selectedDaysSupply, selectedPharmacyType, handlePopupOpen, selectedInsurancePlan, selectedPlanDetails) => {
  // console.log('Selected Plan Details:', selectedPlanDetails);
  // console.log('Deductible value being passed:', selectedPlanDetails ? selectedPlanDetails.deductible : 0);
  return [
    {
      dataIndex: 'proprietaryname',
      title: 'Drug Name',
      render(value, record) {
        const displayValue = record.nonproprietaryname || value; // Use nonproprietaryname if available, otherwise proprietaryname
        return (
          <div>
            {record.nonproprietaryname
              ? <strong>{displayValue}</strong> // Bold if nonproprietaryname is available
              : displayValue // Normal text if nonproprietaryname is missing
            }
          </div>
        );
      },
    },
    {
      dataIndex: 'tier_level_value',
      title: (
        <Tooltip placement="bottom" title={tierTooltipContent}>
          Tier Level Value <QuestionCircleOutlined />
        </Tooltip>
      ),
      render: (tierLevelValue) => {
        // Return only 'Not Covered' if the tier level is not available, without 'Unknown Tier'
        if (tierLevelValue === 'Not Covered') return 'Not Covered';
        
        const description = tierLevelDescriptions[tierLevelValue];
        return description ? `${tierLevelValue} (${description})` : tierLevelValue;
      },
    },
    {
      dataIndex: 'out_of_pocket_cost',
      title: (
        <div>
          Patient Out-of-pocket ($)
          <div style={{ fontSize: 'smaller', color: '#888' }}>
            (% is the percentage of the drug list price)
          </div>
        </div>
      ),
      render: (_, record) => patientOutOfPocketCostRender(
        record, 
        selectedDaysSupply, 
        selectedPharmacyType, 
        selectedPlanDetails ? selectedPlanDetails.deductible : 0 // Safely access deductible or default to 0
      ),
    },
    {
      dataIndex: 'active_ingred_unit',
      title: 'Coverage Requirements',
      render: (_, drug) => {
        return drug.covered ? renderRequirements(drug, selectedInsurancePlan, handlePopupOpen) : '';
      },
    },
  ];
};

const groupDrugsByNonproprietaryName = (drugs) => {
  const groups = {};
  let nullNonproprietaryNameIndex = 0; // To handle drugs with null nonproprietaryname

  drugs.forEach(drug => {
    // If nonproprietaryname is null, create a unique key for each drug
    const key = drug.nonproprietaryname === null ? `null-${nullNonproprietaryNameIndex++}` : drug.nonproprietaryname;

    if (!groups[key]) {
      groups[key] = {
        ...drug,
        proprietarynames: [drug.proprietaryname],
        details: [drug] // Collecting all the details for expandable rows
      };
    } else {
      // If nonproprietaryname is not null, group as before
      groups[key].proprietarynames.push(drug.proprietaryname);
      groups[key].details.push(drug);
    }
  });

  return Object.values(groups); // We only need the grouped drugs for rendering
};


function getCustomTierSortOrder(hasTierSix) {
  return hasTierSix ? [1, 2, 6, 3, 4, 5, 'Not Covered'] : [1, 2, 3, 4, 5, 6, 'Not Covered'];
}

function sortByTier(groups, sortOrder) {
  return groups.sort((a, b) => {
    let tierA = a.tier_level_value === 'Not Covered' ? 'Not Covered' : parseInt(a.tier_level_value, 10);
    let tierB = b.tier_level_value === 'Not Covered' ? 'Not Covered' : parseInt(b.tier_level_value, 10);

    let indexA = sortOrder.indexOf(tierA);
    let indexB = sortOrder.indexOf(tierB);

    if (indexA === -1) indexA = sortOrder.length;
    if (indexB === -1) indexB = sortOrder.length;

    return indexA - indexB;
  });
}


export default function DrugClassResultsTable({ results, selectedInsurancePlan, selectedPlanDetails, expandedRow, setExpandedRow }) {
  useEffect(() => {
    console.log("Updated results in DrugClassResultsTable:", results);
  }, [results]);

  useEffect(() => {
    console.log("Updated expandedRow:", expandedRow);
  }, [expandedRow]);

  
  const toggleDetails = (nameKey) => {
    console.log(`Toggling details for: ${nameKey}, Current expandedRow: ${expandedRow}`);
    const newExpandedRow = expandedRow === nameKey ? null : nameKey;
    setExpandedRow(newExpandedRow);
};


  const [tierDetailsModalVisible, setTierDetailsModalVisible] = useState(false);
  const [tierDetails] = useState(null);
  const [supplyDuration, setSupplyDuration] = useState('30days'); // Default value
  const [pharmacyType, setPharmacyType] = useState('retail'); // Default value
  // const searchBeneficiaryCost = useAPI(api.searchBeneficiaryCost);

  // const [zipCodeModalVisible, setZipCodeModalVisible] = useState(false);
  // const [pharmacySelectionModalVisible, setPharmacySelectionModalVisible] = useState(false);
  // const [zipCode, setZipCode] = useState('');
  // const [pharmacies, setPharmacies] = useState([]);
  const [selectedDrugName, setSelectedDrugName] = useState('');
  // const [selectedPharmacies, setSelectedPharmacies] = useState([]);
  // const [pharmacyCostDetails, setPharmacyCostDetails] = useState([]);
  const [selectedDaysSupply, setSelectedDaysSupply] = useState('30days'); 
  const [selectedPharmacyType, setSelectedPharmacyType] = useState('retail'); 
  const [visiblePopup, setVisiblePopup] = useState(false);
  const [popupContent, setPopupContent] = useState('');
  const [popupTitle, setPopupTitle] = useState('');

  const prepareData = (data) => {
    return data.map((item, index) => ({
      ...item,
      uniqueId: `${item.proprietaryname}-${item.nonproprietaryname}-${index}`
    }));
  };

  const hasTierSix = results.some(drug => drug.tier_level_value === 6);
  const sortOrder = getCustomTierSortOrder(hasTierSix);
  const groupedDrugResults = groupDrugsByNonproprietaryName(results);
  const sortedGroupedDrugResults = sortByTier(groupedDrugResults, sortOrder);
  const preparedData = prepareData(sortedGroupedDrugResults);

  
  const handlePopupClose = () => {
    setVisiblePopup(false);

    if (popupTitle.includes('Working-in-progress')) {
      // The popup was for the prior authorization check, so send the email
      sendEmailNotification(selectedDrugName);
    }
    if (popupContent && selectedDrugName) {
      sendEmailNotification({
        subject: 'Requirement Details Accessed',
        body: `The details for ${selectedDrugName} were accessed: ${popupContent}`
      });
    }
  };
  
  const handlePopupOpen = (drugName, content) => {
    setSelectedDrugName(drugName);
    setPopupContent(content);
    setPopupTitle(`Requirement Details for ${drugName}`);
    setVisiblePopup(true);
  };

  const handlePatientAssistancePopup = (drugName) => {

    const drug = drugsWithPatientAssistance.find(d => d.name === drugName.toLowerCase());
    if (drug) {
      // console.log(`Found drug details: ${drug.url}`); // Debugging log
      const linkHtml = `Working-in-progress. You can access the patient assistance program <a href="${drug.url}" target="_blank" rel="noopener noreferrer">here</a>.`;
      setPopupContent(linkHtml);
      setPopupTitle(`Patient Assistance Details for ${drugName}`);
      setVisiblePopup(true);
    }else {
      // console.log('Drug details not found.'); // Debugging log
    }
  };


  const handleSupplyDurationChange = (newValue) => {
    setSelectedDaysSupply(newValue);
    // additional logic to refresh the data if necessary
  };
  
  const handlePharmacyTypeChange = (newValue) => {
    setSelectedPharmacyType(newValue);
    // additional logic to refresh the data if necessary
  };

  const renderBeneficiaryCostDetails = () => {
    // Check if the current drug is insulin based on the absence of 'coverage_level'
    const isInsulin = tierDetails && tierDetails.length > 0 && tierDetails[0].coverage_level === undefined;
    
    // Use the isInsulin flag to decide which processing function to use
    return isInsulin
      ? processAndDisplayInsulinCosts(tierDetails, supplyDuration, pharmacyType)
      : processAndDisplayBeneficiaryCosts(tierDetails, supplyDuration, pharmacyType);
  };
  

  // const handleZipCodeSubmit = async () => {
  //   const fetchedPharmacies = await fetchPharmacies({
  //     drugName: selectedDrugName,
  //     zipCode,
  //     contractId: selectedPlanDetails.contract_id,
  //     planId: selectedPlanDetails.plan_id,
  //     segmentId: selectedPlanDetails.segment_id,
  //   });
  //   setPharmacies(fetchedPharmacies);
  //   setZipCodeModalVisible(false);
  //   setPharmacySelectionModalVisible(true);
  // };

  // const handlePharmacySelect = async (selectedPharmacyNames) => {
  //   // Filter out the selected pharmacy objects
  //   const selectedPharmacyObjects = pharmacies.filter(pharmacy =>
  //     selectedPharmacyNames.includes(pharmacy.pharmacy_name)
  //   );
  //   setSelectedPharmacies(selectedPharmacyObjects); 
  //   console.log('Selected Pharmacy Objects:', selectedPharmacyObjects);
  
  //   // Fetch cost details for all selected pharmacies
  //   const costDetailsPromises = selectedPharmacyObjects.map(async (pharmacy) => {
  //     try {
  //       console.log('Fetching cost details for:', pharmacy.pharmacy_name);
  //       const costDetailsResponse = await fetchPharmacyCostDetails({
  //         pharmacyName: pharmacy.pharmacy_name,
  //         zipCode,
  //         drugName: selectedDrugName,
  //         contractId: selectedPlanDetails.contract_id,
  //         planId: selectedPlanDetails.plan_id,
  //         segmentId: selectedPlanDetails.segment_id,
  //       });
  //       return { ...costDetailsResponse, pharmacy_name: pharmacy.pharmacy_name }; // Include pharmacy name in the response
  //     } catch (error) {
  //       console.error('Failed to fetch cost details for:', pharmacy.pharmacy_name, error);
  //       return null;
  //     }
  //   });
  
  //   // Wait for all the cost details to be fetched
  //   const costDetailsResponses = await Promise.all(costDetailsPromises);
  
  //   // Filter out any null responses (failed fetches) and update state
  //   const validCostDetails = costDetailsResponses.filter(detail => detail !== null);
  //   setPharmacyCostDetails(validCostDetails); // Store cost details for selected pharmacies
  // };
  
  // const renderZipCodeModal = () => (
  //   <Modal
  //     title="Enter ZIP Code"
  //     visible={zipCodeModalVisible}
  //     onOk={handleZipCodeSubmit}
  //     onCancel={() => setZipCodeModalVisible(false)}
  //   >
  //     <Input placeholder="ZIP Code" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
  //   </Modal>
  // );

//   const renderPharmacySelectionModal = () => (
//     <Modal
//       title="Select Pharmacy"
//       visible={pharmacySelectionModalVisible}
//       onCancel={() => setPharmacySelectionModalVisible(false)}
//       footer={null}
//       className="modal-wide"
//     >
//       <Select
//         mode="multiple"
//         style={{ width: '100%' }}
//         onChange={handlePharmacySelect}
//         placeholder="Select a pharmacy"
//         value={selectedPharmacies.map(pharmacy => pharmacy.pharmacy_name)} // Use pharmacy_name as the value
//         dropdownClassName="wrap-text"
//       >
//         {pharmacies.map((pharmacy, index) => (
//         <Select.Option 
//           key={index} 
//           value={pharmacy.pharmacy_name} 
//           className="wrap-text" // This will ensure the text wraps
//         >
//           {pharmacy.pharmacy_name} - {pharmacy.preferred}
//         </Select.Option>
//       ))}
//     </Select>
//     {pharmacyCostDetails.map((details, index) => (
//       <PharmacyCostDetails
//         key={index}
//         costDetails={details}
//         selectedPharmacy={selectedPharmacies} // Assuming selectedPharmacies aligns with pharmacyCostDetails
//         selectedPlanDetails={selectedPlanDetails} // Pass this prop to ensure access to deductible, etc.
//       />
//     ))}
//     </Modal>
// );

  return (
  <div>
    <SupplyDurationSelector value={selectedDaysSupply} onChange={handleSupplyDurationChange} />
    <PharmacyTypeSelector value={selectedPharmacyType} onChange={handlePharmacyTypeChange} />
    
    <DrugCoverageSummary 
        planDetails={selectedPlanDetails} // Assuming this prop structure fits your component needs
        drugResults={results} // Assuming 'results' holds your drug coverage data
    />

    <Modal
        title={popupTitle} // Use the state variable for the title
        visible={visiblePopup}
        onOk={() => {
          handlePopupClose();
          // If you want to send an email when OK is clicked, you can call sendEmailNotification here.
          sendEmailNotification({
            subject: 'Patient Assistance Program Accessed',
            body: `The patient assistance program for ${selectedDrugName} was accessed.`
          });
        }}
        onCancel={handlePopupClose} // Optionally keep the cancel button behavior the same as OK
        footer={[
          <Button key="submit" type="primary" onClick={handlePopupClose}>
            OK
          </Button>,
        ]}
      >
        <p dangerouslySetInnerHTML={{ __html: popupContent }}></p>
      </Modal>
      <Table
        columns={resultTableColumns(selectedDaysSupply, selectedPharmacyType, handlePopupOpen, selectedInsurancePlan, selectedPlanDetails)}
        dataSource={preparedData}
        rowKey="uniqueId"
        expandable={{
          expandedRowKeys: expandedRow ? [expandedRow] : [],
          onExpand: (expanded, record) => {
            const nameKey = record.uniqueId; // Adjust as needed
            toggleDetails(nameKey);
          },
          expandedRowRender: record => {
            console.log("Attempting to render expanded content for:", record);
            const drugs = getProprietaryNameExpandedRows(record.nonproprietaryname, preparedData);
            return (
              <>
                <Table
                  columns={proprietaryNameDetailColumns(selectedInsurancePlan)}
                  dataSource={drugs}
                  pagination={false}
                  showHeader={true}
                />
              {
                drugsWithPatientAssistance.some(drug => drug.name === record.nonproprietaryname.toLowerCase()) && (
                  <div style={{ textAlign: 'right' }}>
                    <span onClick={() => handlePatientAssistancePopup(record.nonproprietaryname)} style={{ cursor: 'pointer', color: 'blue' }}>
                      Check for Patient Assistance Program
                    </span>
                  </div>
                )
              }
            </>
          );
        },
        expandIcon: ({ expanded, onExpand, record }) => {
          // This checks if there should be an expand icon for the row
          const shouldShowIcon = record.nonproprietaryname;
          
          if (shouldShowIcon) {
            return expanded ? (
              <CaretUpOutlined style={{ fontSize: '20px' }} onClick={e => { e.stopPropagation(); onExpand(record, e); }} />
            ) : (
              <CaretDownOutlined style={{ fontSize: '20px' }} onClick={e => { e.stopPropagation(); onExpand(record, e); }} />
            );
          }
          
          // If there's no `nonproprietaryname`, return null (no icon)
          return null;
        },           
      }}
      pagination={false}
    />
    <Modal
      title="Beneficiary Cost Details"
      visible={tierDetailsModalVisible}
      onOk={() => setTierDetailsModalVisible(false)}
      onCancel={() => setTierDetailsModalVisible(false)}
      footer={null}
    >
      {/* Display Deductible information at the top */}
      {selectedPlanDetails.deductible !== null && selectedPlanDetails.deductible !== undefined && (
        <div>
          <strong>Annual Deductible:</strong> ${selectedPlanDetails.deductible.toFixed(2)}
        </div>
      )}
      {/* Supply Duration and Pharmacy Type Selectors within the Modal */}
      <SupplyDurationSelector value={supplyDuration} onChange={setSupplyDuration} />
      <PharmacyTypeSelector value={pharmacyType} onChange={setPharmacyType} />
      {renderBeneficiaryCostDetails()}
    </Modal>
    {/* {renderZipCodeModal()} 
    {renderPharmacySelectionModal()} */}
    {/* <PharmaciesCostDetailsTable pharmacyDetails={selectedPharmacyDetails} /> */}
    </div>
  );
};